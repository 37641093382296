<template>
  <div>
    <short-issue-list
      v-if="issues.length"
      :issues="issues"
      class="mb-4"
      list-only
    />
    <div v-else class="mb-5 ml-2">{{ $t('noIssues') }}</div>
    <new-issue-dialog
      ref="newIssueDialog"
      @onSave="init"
    />
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import { issueMixin } from '@/mixins/issue-mixin'
import ShortIssueList from '@/components/issue/ShortIssueList.vue'
import NewIssueDialog from '@/components/issue/NewIssueDialog.vue'
import messages from '@/componet-locale/et-item/messages'

export default {
  name: 'EtItemIssues',
  i18n: { messages },
  components: { ShortIssueList, NewIssueDialog },
  mixins: [issueMixin],
  props: {
    et: Object,
    item: Object
  },
  data: () => ({
    issues: [],
    issueLink: null
  }),
  mounted () {
    this.issueLink = this.createEtItemIssueLink(this.et, this.item)
    this.init()
  },
  methods: {
    init () {
      this.$axios.get('/issue/get-by-et-item', {
        params: {
          etId: this.issueLink.etId,
          itemPrimaryKey: this.issueLink.itemPrimaryKey
        }
      })
        .then(res => this.issues = res.data || [])
        .catch(() => EventBus.$emit('showErrorMessage'))
    },
    createNewIssue () {
      this.$refs.newIssueDialog.open(null, this.issueLink)
    }
  }
}
</script>
